import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import SStyle from '../../components/site.module.scss'

const VenuePage = () => (
  <div>
 


 <p style={{fontSize: 1.2 + 'em'}}><br/><br/>
  We have registries with the following retailers. Please visit one of the sites below: <br/><br/><br/>
  <a href="https://registry.thebay.com/registry/view-registry/holly_shoemaker"> <StaticImage src="../../images/hudsonbay.svg" width={300} alt="HBC" /></a> 
  <br/><br/><br/>
     <a href="https://www.amazon.ca/wedding/share/hollyandnicolas">  <StaticImage src="../../images/Amazon_logo.svg" width={200} alt="Amazon" /> </a> <br/>
    


<br/><br/>

     
     <a href="https://www.bedbathandbeyond.ca/store/giftregistry/viewregistryguest/550421813?selectedRLVFilters=_recommended"> <StaticImage src="../../images/Bedbath&beyond.png" width={200}alt="BB&B" /></a> 
     
     <br/>


  <br/>
</p><div className={SStyle.hidden}>

</div>
</div>
)

export default VenuePage
